<template>
  <div class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1>
          <i class="fas fa-people-arrows gradient"></i> Fornecedores de Produtos
        </h1>
      </div>
      <div slot="extra">
        <a-button
          type="primary"
          ghost
          @click="openCreateProductSupplier = true"
        >
          <i class="fas fa-people-arrows mr-10"></i> NOVO FORNECEDOR
        </a-button>
      </div>
    </a-page-header>

    <a-tabs type="card" @change="changeOrdersTabs">
      <a-tab-pane key="Ativo" tab=" ATIVOS"> </a-tab-pane>
      <a-tab-pane key="Desativado" tab=" DESATIVADOS"> </a-tab-pane>
    </a-tabs>

    <a-collapse
      class="travel-filters expandable mt-10 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row
          class="mt-5"
          :gutter="20"
          style="margin-left: 0; margin-right: 0"
        >
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome do fornecedor</label>
              <a-input
                placeholder="Escreva o nome"
                v-model="productSupplier.filters.searchTerm"
                @change="getProductSuppliers()"
              />
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Categoria</label>
              <a-select
                class="travel-input"
                placeholder="Selecione uma categoria"
                mode="multiple"
                v-model="productSupplier.filters.category.selected"
                show-search
                style="width: 100%"
                @change="getProductSuppliers()"
              >
                <a-select-option
                  v-for="(item, index) of productSupplier.filters.category.list"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Estado</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais estados"
                mode="multiple"
                v-model="productSupplier.filters.state.selected"
                show-search
                style="width: 100%"
                @change="getProductSuppliers()"
              >
                <a-select-option
                  v-for="(item, index) of productSupplier.filters.state.list"
                  :key="index"
                  :value="item.initials"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Cidade</label>
              <a-input
                placeholder="Escreva o nome da cidade"
                v-model="productSupplier.filters.city"
                @change="getProductSuppliers()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="productSuppliersTableCollumns"
      :data-source="productSupplier.list"
      :loading="productSupplier.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="productSupplierTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="trading_name" slot-scope="record">
        <div class="truncate">{{ record.trading_name }}</div>
      </div>
      <div slot="category" slot-scope="record">
        {{ formatCategory(record.category) }}
      </div>
      <div slot="company_name" slot-scope="record">
        <div class="truncate">{{ record.company_name }}</div>
      </div>
      <template slot="action" slot-scope="record">
        <div class="travel-actions actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="productSupplier.pagination.page"
        :total="productSupplier.pagination.total"
        @change="changeProductSuppliersPage"
        @showSizeChange="changeProductSuppliersPageSize"
      />
    </div>

    <a-drawer
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateProductSupplier"
      @close="openCreateProductSupplier = false"
    >
      <template slot="title">
        <i class="fas fa-people-arrows cprimary mr-5"></i> NOVO FORNECEDOR
      </template>

      <a-form :form="form" @submit="handleSubmit">
        <EditProductSupplierDefaultData
          v-if="openCreateProductSupplier"
          :form="form"
          :edit="false"
          :showField="true"
          @listProductSuppliers="listProductSuppliers"
        />
        <a-row>
          <a-col class="a-center mt-10" :span="24">
            <a-button
              size="large"
              type="primary"
              :loading="loadingCreateProductSupplier"
              html-type="submit"
            >
              CADASTRAR FORNECEDOR
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-drawer>
  </div>
</template>

<script>
import EditProductSupplierDefaultData from "@/components/product-suppliers/forms/EditProductSupplierDefaultData.vue";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  components: { EditProductSupplierDefaultData },
  mixins: [productSupplierMixins],
  data() {
    return {
      activeProductSupplierTab: "Ativo",
      openCreateProductSupplier: false,
      form: this.$form.createForm(this),
    };
  },
  beforeMount() {
    this.productSupplier.filters.status.selected = "Ativo";
    this.getProductSuppliers();

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=50&category_id=9&status=Ativo&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.productSupplier.filters.category.list = data.data;
      });
  },
  methods: {
    changeOrdersTabs(tab) {
      this.activeProductSupplierTab = tab;
      this.productSupplier.filters.status.selected = tab;
      this.getProductSuppliers();
    },
    formatCategory(categories) {
      let txt = "";
      JSON.parse(categories).forEach((category) => {
        txt += category + ", ";
      });

      txt = txt.slice(0, -2);

      return txt;
    },
    listProductSuppliers() {
      this.getProductSuppliers();
      this.openCreateProductSupplier = false;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.category = JSON.stringify(values.category);
        values.product_supplier_ids = JSON.stringify(
          values.product_supplier_ids
        );
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        console.log(err, values);
        if (!err) {
          this.loadingCreateProductSupplier = true;

          this.$http
            .post("/product-supplier/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateProductSupplier = false;
              this.listProductSuppliers();
              //this.$emit("listProductSuppliers", true);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateProductSupplier = false;
            });
        }
      });
    },
  },
};
</script>
